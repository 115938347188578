@use 'libs/heartbeat/src/lib/assets/scss/hb-foundations' as *;
@use 'config/variables' as *;

// Flex grid

.cdl-flex {
  display: flex;
}

.cdl-flex.flex {
  flex: 1;
}

.cdl-flex.wrap {
  flex-wrap: wrap;
}

.cdl-flex.horizontal {
  flex-direction: row;

  &.space-between {
    justify-content: space-between;
  }

  &.space-between.center {
    align-items: center;
  }

  &-space-between {
    justify-content: space-between;
  }

  &-space-between.center {
    align-items: center;
  }

  &-space-around {
    justify-content: space-around;
  }

  &-center {
    justify-content: center;
  }

  &-center.center {
    align-items: center;
  }

  &-end {
    justify-content: flex-end;
  }

  &-end.center {
    align-items: center;
  }

  &.end {
    align-items: flex-end;
  }

  &.start {
    justify-content: flex-start;
  }

  &.start.center {
    align-items: center;
  }
}

.cdl-flex.vertical {
  flex-direction: column;

  &.space-between {
    justify-content: space-between;
  }

  &.space-around {
    justify-content: space-around;
  }

  &.space-around.center {
    align-items: center;
  }

  &.start {
    justify-content: flex-start;
  }

  &.start.start {
    align-items: flex-start;
  }

  &.start.center {
    align-items: center;
  }

  &.end {
    justify-content: flex-end;
  }

  &.center {
    justify-content: center;
  }

  &.center-center {
    justify-content: center;
    align-items: center;
  }
}
